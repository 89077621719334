import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
import Emoji from '@widgets/Emoji'

const Commitment = props => (
  <Section aside title='Sứ mệnh' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Truyền cảm hứng tham gia vào các hoạt động ngoài trời thông qua những câu chuyện, chia sẻ nhằm góp phần xây dựng một cộng đồng năng động <Emoji symbol="🔥" label="fire"/> khoẻ mạnh <Emoji symbol="💪" label="flexed-biceps" />
      </Text>
    </Card>
  </Section>
)

export default Commitment
